import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://00eac90f48a249129d3385670d40bd04@o64315.ingest.sentry.io/4505357210812416',
	tracesSampleRate: 0.1,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 0.1,

	// If you don't want to use Session Replay, just remove the line below:
	integrations: [new Replay()],
	ignoreErrors: [
		'TypeError: Failed to fetch', // Occurs when the user is offline
		'TypeError: NetworkError when attempting to fetch resource.' // Occurs when the user is offline
	]
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
